.wrapper {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 0px;
  /* border: 1px solid blueviolet; */
}

/* .inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 30px;
  margin-bottom: 30px;
} */

button {
  margin:3px 3px; 
  color:white; 
  border:1px solid  green;
  padding:1px 5px; 
  border-radius:10px;
  background-color:green;
}

.input {
  height: 2cqb;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  margin-top: 5px;
  border: none;
  gap:10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: antiquewhite;
  border: 1px solid blue;}

.textareas {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  display: grid;
  width:220px;
  grid-template-columns: 4fr 1fr;
  gap: 20px;
}

.textarea {
  margin-left: auto;
  margin-right: auto;
  min-height: 150px;
  min-width: 200px;
  border-radius: 5px;
  border: none;
  padding: 20px;
  font-size: 16px;
  background-color: antiquewhite;
  border: 1px solid blue;
}

.send {
  background-color: lightgreen;
  border: none;
  border-radius: 15px;
  font-size: 16px;
}

.send:hover {
  background-color: #1fffad;
  cursor: pointer;
}

.icon {
  width: 40px;
  height: 40px;
}

@media only screen and (max-width: 560px) {
  .inputs {
    grid-template-columns: 1fr;
  }

  .textareas {
    grid-template-columns: 1fr;
  }

  .send {
    /* min-height: 30px; */
    height: 30px;
    width: 30px;
  }
}