.wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.brand {
  display: grid;
  grid-template-columns: 10px 0px;
  max-width: 300px;
  gap: 0px;
  place-items: center;
  border:1px solid purple;
}

.brand:hover {
  cursor: pointer;
}

.icon {
  width: 30px;
  height: 30px;
  /* border: 1px solid blue; */
}

.logo {
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans;
  color: #2c2851;
  margin: 0  5px;
  /* border: 1px solid red; */
}

button {
  margin:3px 3px; 
  color:white; 
  border:1px solid  green;
  padding:1px 5px; 
  border-radius:10px;
  background-color:green;
}

.toggle {
  display: none;
  cursor: pointer;
}
.nava {
  display: contents;
  /* border:  solid green; */
  /* background-color: aqua; */
  padding: 10px;
  width:77px;
}
.navigation {
  padding: 10px;
  /* display: contents; */
  /* display: inline; */
  /* display: grid; */
  /* display:block; */
  /* position:absolute; */
  left: 170px;
  width: auto;
  /* margin-left: 0px; */
  display: table;
  /* position: static; */
  z-index: 10;
  height: 25vh;
  /* justify-content: space-between; */
  align-items: left;
  /* gap: 20px; */
  font-size: 18px;
  /* color:green; */
  background-color:bisque;
  /* border: 1px solid red; */


}

.item {
  color: #2c2851;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .toggle {
    display: block;
    height: 35px;
    /* z-index: 10; */
  }

  /* .navigation { */
    /* display: grid; */
    /* display: block; */
    /* gap: 0px; */
    /* top: 0; */
    /* left: 0; */
    /* position: absolute; */
    /* font-size: 22px; */
    /* font-weight: bold; */
    /* height: 100vh; */
    /* z-index: 10; */
    /* height: 30vh; */
    /* width: auto; */
    /* margin: 0%; */
    /* padding: 0%; */
    /* background-color: #efeff1; */
    /* background-color: lightgoldenrodyellow; */
  /* } */

  .item {
    width: 100vw;
  }
}