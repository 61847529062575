.wrapper {
  /* border: 0px solid  red; */
  position: relative;
  top:0px;
  bottom: 0;
  width: 100%;
  height: 200px;
  text-align: center;
  padding: 10px;
  color: #85819f;
}

.title {
  align-items: center;
  text-align: center;
  color: #2c2851;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3px;
  font-family: "Montserrat", sans;
}

.socials {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 150px;
  margin: 20px auto 0 auto;
  place-items: center;
}

.icon {
  width: 20px;
  height: 20px;
}

.icon:hover {
  cursor: pointer;
}