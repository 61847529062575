/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Varela+Round&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Varela Round", sans-serif;
}

body {
  /* background-color: #efeff1; */
  background-color:bisque;
}

.title {
  font-family: "Montserrat", sans-serif;
  margin: 40px 0 20px 0;
  color: #2c2851;
  font-size: 40px;
}

.description {
  margin-bottom: 30px;
  /* color: #85819f; */
  color:darkblue;
  font-weight: bold;
  font-size: 18px;
}

.App {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
}
/* .mylink a:link {
  margin: 5px 5px;
  background-color:darkgreen;
  color: white;
  padding: 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius:15px;
} */