.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  max-width: 800px;
  margin: 0 auto;
}

.card {
  /* background-color: white; */
  /* padding: 10px; */
  /* border-radius: 10px; */
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr;
  margin-left: auto;
  margin-right: auto; */
  padding:10px;
  border-radius: 10px;
  background-color:antiquewhite;
  border: 2px solid blue;
  border-radius: 15px;
}

.icon {
  width: 150px;
  height: 100px;
  border-radius: 10px;
}
.image {
  height: 100px;
  width: auto;
}
.title {
  margin-bottom: 10px;
  color: #2c2851;
  font-family: "Montserrat", sans-serif;
}

.description {
  /* margin-bottom: 30px; */
  /* color: #85819f; */
  padding:15px;
  color:darkblue;
  font-weight:bold;
  font-size: 18px;
  text-align: left;
}
/* .description { */
  /* color: #85819f; */
  /* color:darkmagenta; */
  /* font-weight: bold; */
/* } */
/* Myspan {
  border:1px solid red;
  margin-top: 10px;
} */



a:link, a:visited {
  margin-top: 0px;
  background-color:darkgreen;
  color: white;
  padding: 3px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius:15px;
}

.mylink a{
background-color: transparent;
}

@media only screen and (max-width: 560px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
}