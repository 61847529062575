.wrapper {
  margin-top: -25px;
  /* display: grid; */
  /* gap: 30px; */
  max-width: auto;
  /* margin: 0 auto; */
  /* border: 1px solid red; */
}

button {
  margin:3px 3px; 
  color:white; 
  border:1px solid  green;
  padding:1px 5px; 
  border-radius:10px;
  background-color:green;
}
.card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  min-height: 120px;
  border-radius: 10px;
  background-color:antiquewhite;
  border: 2px solid blue;
  border-radius: 15px;
}

.image {
  width: 150px;
  height: 100px;
}

.info {
  padding: 10px;
  display: grid;
  place-items: center;
}

.title {
  margin-bottom: 10px;
  color: #2c2851;
  font-family: "Montserrat", sans-serif;
}

.description {
  /* color: #85819f; */
  color:darkblue;
  font-weight: bold;
  margin-bottom: 5px;
}

@media only screen and (max-width: 560px) {
  .card {
    grid-template-columns: 1fr;
  }
}